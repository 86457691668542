import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Jan Cairns" subtitle="PTA President" mdxType="AuthorBlock">
      <p>{`  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "686px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fd48a0ab7311ac36e1d267e110b19b69/9050f/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADBf/EABcBAAMBAAAAAAAAAAAAAAAAAAIDBAH/2gAMAwEAAhADEAAAAQKyRhKhzZSq5sqqsog//8QAHxAAAgAFBQAAAAAAAAAAAAAAAQIAAwQRExASIiMx/9oACAEBAAEFAvA2aUnGAAWqLYpR6wGeKlG2hrQrFCzs2n//xAAZEQACAwEAAAAAAAAAAAAAAAAAAQIQIRL/2gAIAQMBAT8BUcGdZX//xAAXEQADAQAAAAAAAAAAAAAAAAAQESES/9oACAECAQE/AXRmj//EAB0QAAEEAgMAAAAAAAAAAAAAAAEAAhARQVESIjH/2gAIAQEABj8CXJz7Gobe0fUFTcK8CLaaXZxMf//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQWEQkaH/2gAIAQEAAT8h30bxKDSbbEVJpL3OMbDqbYDMt6cREG8YoUvYrK3PuWwLqfbB8f/aAAwDAQACAAMAAAAQgNM+/8QAGBEBAQEBAQAAAAAAAAAAAAAAAQAhETH/2gAIAQMBAT8QHbDikeEg7f/EABgRAQEBAQEAAAAAAAAAAAAAAAEAIRFB/9oACAECAQE/EF4jS9IeX//EAB4QAQEBAQACAgMAAAAAAAAAAAERACExQWGRcYGh/9oACAEBAAE/EFGBeH5wbzPQQuoviHXvXmjSDLzn91RAka59aEWpv9ZV7aY+MEx0eN+O5IwVvJ1L2Srelns4fWVu/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/fd48a0ab7311ac36e1d267e110b19b69/9050f/01.jpg",
              "srcSet": ["/static/fd48a0ab7311ac36e1d267e110b19b69/f93b5/01.jpg 300w", "/static/fd48a0ab7311ac36e1d267e110b19b69/b4294/01.jpg 600w", "/static/fd48a0ab7311ac36e1d267e110b19b69/9050f/01.jpg 686w"],
              "sizes": "(max-width: 686px) 100vw, 686px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`Each year The Parent Teacher Association (PTA) develops a strong partnership with teachers, parents, students and staff to build a community spirit  where all stakeholders feel valued and contribute to making PSI feel like a ‘Place Where We Belong'.`}</p>
    </blockquote>
    <p>{`Living by our motto that Together Everyone Achieves More seemed even more important this year as our community was plunged into a virtual world of communication. True to the spirit of PSI, these challenges were used as an opportunity to learn and grow and the PTA played its part by providing  students and families with online activities such as an Easter Egg Painting Competition, Art in Quarantine, Primary Art Auction and Summer Bazaar. Most importantly members of the PTA were available to listen and support those parents who just needed someone to talk to about the challenges they faced.`}</p>
    <p>{`Appreciation for our community and the memories created during the first quarter of the year are even more valued after this ‘isolation’ experience. I remember the excitement the PTA Team felt at the New Family Orientation Day as we welcomed  new members into the PSI community and at a later date got better acquainted at our annual Cheese and Wine Party. None of those in attendance can forget the ambience of the Back to School Picnic, watching students' play together whilst adults mingled and enjoyed the delicious food on offer. International Night never fails to disappoint and this year was no exception as we celebrated  the unique qualities of each and every member of our community by sharing our traditions and cultures from around the world, once again demonstrating a strong sense of unity. The year ended with another special event which saw us welcome Ukrainian artists into the school for the Winter Bazaar. This combined with the Primary student concert, Secondary Student Council Bake Sale and CAS Fundraiser made for a heartwarming celebration that put us all in the holiday mood.`}</p>
    <p>{`Beyond organising community events, the PTA took pride in supporting student-led projects which benefited both the local and extended community. Listening to students present their project achievements was living testimony not only to the quality of education students receive at PSI but also to the way they develop as caring, global citizens prepared to become advocates for change and social justice in the world.`}</p>
    <p>{`Finally, the PTA wishes to thank all of the members of our PSI Community for their unconditional support and contributions throughout the year; without which, none of the above would have been possible.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      